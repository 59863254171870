import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private storage: any = {};

  constructor() {}

  addItem(key: string, value: string) {
    localStorage.setItem(key, value);
    this.setLocalData(value, key);
  }

  getItem(key: string) {
    if (this.storage[key]) {
      return this.storage[key];
    }
    const value: any = localStorage.getItem(key);
    if (value) {
      this.setLocalData(value, key);
    }
    return this.storage[key] || null;
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
    delete this.storage[key];
  }

  saveContent(data: any) {
    Object.keys(data).forEach((key) => {
      localStorage.setItem(key, data[key]);
    });
  }

  clearStorage() {
    localStorage.clear();
    this.storage = {};
  }

  setLocalData(value: string, key: string) {
    if (value) {
      const index: number = value.indexOf('{');
      if (index > -1) {
        this.storage[key] = JSON.parse(value);
      } else {
        this.storage[key] = value;
      }
    }
  }
}
