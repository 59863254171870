import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { LocalStorageService } from '../services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class NegateAuthGuard {
  constructor(
    private localStorageService: LocalStorageService,
    private router: Router
  ) {}

  canActivate(): boolean {
    // Check if not authenticated, then navigate to sign-in
    const token = this.localStorageService.getItem('token');
    if (token) {
      this.router.navigate(['portal']);
      return false;
    }

    return true;
  }
}
